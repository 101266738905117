import axios from "axios";

/**
 * Base URL to make requests to Lambda
 */
const instance = axios.create({
  baseURL: "https://jmv0mw4cof.execute-api.us-east-1.amazonaws.com/prod",
});

export default instance;
