import React from "react";
import Box from "common/components/Box";
import { FinalFigureWrapper, FactorWrapper } from "./results.style";
import { Icon } from "react-icons-kit";
import { arrowUpThin } from "react-icons-kit/metrize/arrowUpThin";
import { arrowDownThin } from "react-icons-kit/metrize/arrowDownThin";
import ErrorMessage from "../Error/error";

const row = {
  flexBox: true,
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
};

const textArea = {
  width: "100%",
};

const HEALTHY_UNEMPLOYMENT_RATE = 5;

function Results({
  layoffData,
  occupationDisplay,
  performanceReviewDisplay,
  seniorityDisplay,
  sectorDisplay,
}) {
  if (layoffData == null) {
    return <ErrorMessage />;
  }
  return (
    <Box {...row}>
      <Box {...textArea}>
        {FinalFigure(layoffData)}
        {SectorFactor(layoffData, sectorDisplay)}
        {StockMarketFactor(layoffData, sectorDisplay)}
        {OccupationFactor(layoffData, occupationDisplay)}
        {SeniorityFactor(layoffData, seniorityDisplay)}
        {PerformanceReviewFactor(layoffData, performanceReviewDisplay)}
      </Box>
    </Box>
  );
}

/**
 * Final figure
 */
function FinalFigure(layoffData) {
  const finalFigure = layoffData["final_figure_pct"];

  return (
    <FinalFigureWrapper
      healthy={parseFloat(finalFigure) < HEALTHY_UNEMPLOYMENT_RATE}
    >
      <div>
        <p className="no-margins">
          {finalFigure}
          <span className="tiny-percent">%</span>
          <span className="explanation"> chance of layoffs</span>
        </p>
      </div>
    </FinalFigureWrapper>
  );
}

/**
 * Sector factor
 */
function SectorFactor(layoffData, sectorDisplay) {
  const blsJson = layoffData["bls"];
  // const currentUnemploymentRate = blsJson["current_unemployment_rate_pct"];
  const historicalDiff = blsJson["historical_diff_pct"];
  const lowerOrHigher = historicalDiff > 0 ? "higher" : "lower";
  const historicalDiffAbs = Math.abs(historicalDiff);
  const direction = blsJson["direction"];
  console.log("direction in SectorFactor: ", direction);
  return (
    <FactorWrapper direction={direction}>
      <div>
        <p>
          <DynamicIcon direction={direction} />
          <b>{sectorDisplay}</b> unemployment rate:{" "}
          <span className="direction-text">
            {historicalDiffAbs}% {lowerOrHigher}
          </span>{" "}
          than the historical average
        </p>
      </div>
    </FactorWrapper>
  );
}

/**
 * Occupation factor
 */
function OccupationFactor(layoffData, occupationDisplay) {
  const occupationJson = layoffData["occupation"];
  const occupationGrowthExpl = occupationJson["occupation_growth_expl"];
  const occupationGrowthPct = occupationJson["occupation_growth_pct"];
  const direction = occupationJson["direction"];

  return (
    <FactorWrapper direction={direction}>
      <div>
        <p>
          <DynamicIcon direction={direction} />
          <b>{occupationDisplay}</b>:{" "}
          <span className="direction-text">{occupationGrowthExpl}</span> growth
          rate of <span className="direction-text">{occupationGrowthPct}%</span>{" "}
          by 2030
        </p>
      </div>
    </FactorWrapper>
  );
}

/**
 * Stock market factor
 */
function StockMarketFactor(layoffData, sectorDisplay) {
  const stockMarketJson = layoffData["stock_market"];
  const stockMarketPerf = stockMarketJson["sector_perf_pct"];
  const upOrDown = stockMarketPerf > 0 ? "up" : "down";
  const strongerOrWeaker = stockMarketPerf > 0 ? "stronger" : "weaker";
  const stockMarketAbs = Math.abs(stockMarketPerf);
  const direction = stockMarketJson["direction"];

  return (
    <FactorWrapper direction={direction}>
      <div>
        <p>
          <DynamicIcon direction={direction} />
          <b>{sectorDisplay}</b> stocks:{" "}
          <span className="direction-text">
            {upOrDown} {stockMarketAbs}%
          </span>{" "}
          this past quarter, a sign of{" "}
          <span className="direction-text">{strongerOrWeaker}</span> corporate
          health
        </p>
      </div>
    </FactorWrapper>
  );
}

/**
 * Seniority factor
 */
function SeniorityFactor(layoffData, seniorityDisplay) {
  const seniorityJson = layoffData["seniority"];
  const direction = seniorityJson["direction"];
  const lessOrMoreLikely = seniorityJson["direction_expl"];
  const lessOrMoreSeniority = direction === "good" ? "more" : "less";

  return (
    <FactorWrapper direction={direction}>
      <div>
        <p>
          <DynamicIcon direction={direction} />
          <b>{seniorityDisplay}</b>:{" "}
          <span className="direction-text">{lessOrMoreLikely}</span> to be laid
          off due to{" "}
          <span className="direction-text">{lessOrMoreSeniority}</span>{" "}
          seniority
        </p>
      </div>
    </FactorWrapper>
  );
}

/**
 * Performance review factor
 */
function PerformanceReviewFactor(layoffData, performanceReviewDisplay) {
  const performanceReviewJson = layoffData["perf_review"];
  const lessOrMoreProne = performanceReviewJson["direction_expl"];
  const sentenceConn = performanceReviewJson["sentence_conn"];
  const firstOrLast = sentenceConn === "top" ? "last" : "first";
  const direction = performanceReviewJson["direction"];

  return (
    <FactorWrapper direction={direction}>
      <div>
        <p>
          <DynamicIcon direction={direction} />
          <b>{performanceReviewDisplay}</b> performance:{" "}
          <span className="direction-text">{lessOrMoreProne}</span> to lay offs
          as <span className="direction-text">{sentenceConn}</span> workers are{" "}
          <span className="direction-text">{firstOrLast}</span> to be laid off
        </p>
      </div>
    </FactorWrapper>
  );
}

/**
 * Generates a dynamic icon based on direction (up or down)
 */
function DynamicIcon(direction) {
  if (direction["direction"] === "good") {
    const icon = { color: "#52ee7e", paddingRight: "1%" };
    return <Icon style={icon} icon={arrowUpThin} size={30} />;
  }
  const icon = { color: "#e85968", paddingRight: "1%" };
  return <Icon style={icon} icon={arrowDownThin} size={30} />;
}

export default Results;
