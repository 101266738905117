import React from "react";
import ReactSelect, { createFilter, components } from "react-select";
import PropTypes from "prop-types";
import SelectStyle from "./select.style";

const Select = ({
  className,
  labelText,
  labelPosition,
  changeHandler,
  ...props
}) => {
  // Add all classes to an array
  const addAllClasses = ["reusecore__select"];

  // Add label position class
  if (labelPosition) {
    addAllClasses.push(`label_${labelPosition}`);
  }

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  const LabelField = labelText && (
    <span className="reusecore__field-label">{labelText}</span>
  );

  const position = labelPosition || "top";

  /**
   * Limits to 50 search results for performance
   */
  const MenuList = ({ children, ...props }) => {
    return (
      <components.MenuList {...props}>
        {
          Array.isArray(children)
            ? children.slice(0, 20) /* Options */
            : children /* NoOptionsLabel */
        }
      </components.MenuList>
    );
  };

  return (
    <SelectStyle className={addAllClasses.join(" ")}>
      {position === "left" || position === "right" || position === "top"
        ? LabelField
        : ""}

      <ReactSelect
        captureMenuScroll={false}
        className="select-field__wrapper"
        classNamePrefix="select"
        onChange={changeHandler}
        filterOption={createFilter({ ignoreAccents: false })}
        components={{ MenuList }}
        {...props}
      />
      {position === "bottom" && LabelField}
    </SelectStyle>
  );
};

Select.propTypes = {
  /** You can add your custom class for select wrapper component.
   * note: We manualy add react-select className and classNamePrefix props value */
  className: PropTypes.string,

  /** labelText of the select field */
  labelText: PropTypes.string,

  /** Set label position of the select field. By default it's top */
  labelPosition: PropTypes.oneOf(["top", "bottom", "left", "right"]),
};

Select.defaultProps = {
  as: "div",
  labelPosition: "top",
};

export default Select;
