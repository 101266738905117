import styled from "styled-components";

const BannerWrapper = styled.section`
  padding-top: 150px;
  padding-bottom: 160px;
  background-color: black;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  align-items: center;
  @media (max-width: 990px) {
    padding-top: 170px;
    padding-bottom: 120px;
    min-height: auto;
  }
  @media (max-width: 992px) {
    padding-top: 150px;
    padding-bottom: 60px;
  }
  .particle {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    @media (max-width: 767px) {
      display: none;
    }
  }
  .banner_container {
    position: relative;
  }
`;

export const HeaderWrapper = styled.div`
  h1 {
    color: white;
    font-weight: 400;
    font-size: 25px;
    letter-spacing: -0.025em;
    line-height: 1.31;
    text-align: center;

    @media (min-width: 425px) {
      font-size: 26px;
    }
    @media (min-width: 575px) {
      font-size: 32px;
    }
    @media (min-width: 768px) {
      font-size: 42px;
    }
    @media (min-width: 1024px) {
      font-size: 50px;
    }
    @media (min-width: 1440px) {
      font-size: 55px;
    }
  }
`;

export const SearchWrapper = styled.div`
  @media (min-width: 992px) {
    display: flex;
    align-items: stretch;
    height: 70px;
    box-shadow: 0px 6px 15.98px 1.02px rgba(56, 86, 122, 0.1);
    border-radius: 10px;
  }

  .search_select_left {
    min-width: 165px;
    @media (max-width: 992px) {
      height: 52px;
      margin-bottom: 20px;
    }
    .select__control,
    .select-field__wrapper {
      height: 100%;
    }
    .select__control {
      padding: 0 15px 0 10px;
      box-shadow: none;
      position: relative;
      border-color: #ebebeb;
      @media (min-width: 992px) {
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
        border-color: #f4f5f7;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
        &:before {
          content: "";
          position: absolute;
          width: 1px;
          height: 55%;
          background: #d9d9d9;
          display: block;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }

      .select__placeholder {
        font-size: 17px;
        color: #5d646d;
      }
      .select__indicator {
        color: #0f2137;
      }
    }
    .select__indicator-separator {
      display: none;
    }
  }

  .search_select {
    min-width: 165px;
    @media (max-width: 992px) {
      height: 52px;
      margin-bottom: 20px;
    }
    .select__control,
    .select-field__wrapper {
      height: 100%;
    }
    .select__control {
      padding: 0 15px 0 10px;
      box-shadow: none;
      position: relative;
      border-color: #ebebeb;
      @media (min-width: 992px) {
        border-color: #f4f5f7;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
        &:before {
          content: "";
          position: absolute;
          width: 1px;
          height: 55%;
          background: #d9d9d9;
          display: block;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }

      .select__placeholder {
        font-size: 17px;
        color: #5d646d;
      }
      .select__indicator {
        color: #0f2137;
      }
    }
    .select__indicator-separator {
      display: none;
    }
  }

  .search_select_right {
    min-width: 165px;
    @media (max-width: 992px) {
      height: 52px;
      margin-bottom: 120px;
    }
    .select__control,
    .select-field__wrapper {
      height: 100%;
    }
    .select__control {
      padding: 0 15px 0 10px;
      box-shadow: none;
      position: relative;
      border-color: #ebebeb;
      @media (min-width: 992px) {
        border-color: #f4f5f7;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
        &:before {
          content: "";
          position: absolute;
          width: 1px;
          height: 55%;
          background: #d9d9d9;
          display: block;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }

      .select__placeholder {
        font-size: 17px;
        color: #5d646d;
      }
      .select__indicator {
        color: #0f2137;
      }
    }
    .select__indicator-separator {
      display: none;
    }
  }

  .domain_search_button {
    width: 160px;
    @media (min-width: 992px) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    @media (max-width: 992px) {
      height: 52px;
      width: 100%;
    }
  }
`;

export const List = styled.ul`
  text-align: center;
  margin-top: 17px;
  li {
    display: inline-block;
    font-size: 17px;
    font-weight: 400;
    color: #0f2137;
    padding: 8px 12px;

    a {
      font-size: 15px;
      color: #87909b;
      display: block;
      &:hover {
        color: #eb4d4b;
      }
    }
  }
`;

export default BannerWrapper;
