const colors = {
  transparent: "transparent", // 0
  black: "#000000", // 1
  white: "#ffffff", // 2
  headingColor: "#0f2137",
  textColor: "#5d646d", // 3
  labelColor: "#ffffff", // 4
  inactiveField: "#f2f2f2", // 5
  inactiveButton: "#b7dbdd", // 6
  inactiveIcon: "#EBEBEB", // 7
  primary: "#e85968", // 8
  primaryHover: "#e85968", // 9
  secondary: "#ff5b60", // 10
  secondaryHover: "#FF282F", // 11
  yellow: "#fdb32a", // 12
  yellowHover: "#F29E02", // 13
  primaryBoxShadow: "0px 8px 20px -6px rgba(235,77,75,0.6)",
};

export default colors;
