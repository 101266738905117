import styled from "styled-components";

export const FinalFigureWrapper = styled.div`
  color: ${(props) => (props.healthy ? "#52ee7e" : "#e85968")};
  line-height: 1.31;
  font-weight: 100;
  letter-spacing: 0.015em;
  margin-bottom: 20px;
  text-align: center;
  font-size: 40px;

  @media (min-width: 575px) {
    font-size: 50px;
  }
  @media (min-width: 768px) {
    font-size: 65px;
  }
  @media (min-width: 1024px) {
    font-size: 80px;
  }
  @media (min-width: 1440px) {
    font-size: 100px;
    margin-bottom: 20px;
  }

  .tiny-percent {
    color: ${(props) => (props.healthy ? "#52ee7e" : "#e85968")};
    font-size: 20px;

    @media (min-width: 425px) {
      font-size: 20px;
    }
    @media (min-width: 768px) {
      font-size: 25px;
    }
    @media (min-width: 1024px) {
      font-size: 30px;
    }
    @media (min-width: 1440px) {
      font-size: 45px;
    }
  }

  .explanation {
    color: white;
    font-size: 20px;

    @media (min-width: 425px) {
      font-size: 20px;
    }
    @media (min-width: 768px) {
      font-size: 22px;
    }
    @media (min-width: 1024px) {
      font-size: 25px;
    }
    @media (min-width: 1440px) {
      font-size: 28px;
    }
  }

  .no-margins {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

export const FactorWrapper = styled.div`
  color: white;
  margin-bottom: 35px;
  line-height: 1.75;
  font-size: 15px;
  font-weight: 100;

  @media (min-width: 425px) {
    font-size: 16px;
    margin-bottom: 35px;
  }
  @media (min-width: 575px) {
    font-size: 17px;
    margin-bottom: 40px;
  }
  @media (min-width: 768px) {
    font-size: 20px;
    margin-bottom: 45px;
  }
  @media (min-width: 1024px) {
    font-size: 21px;
    margin-bottom: 55px;
  }
  @media (min-width: 1440px) {
    font-size: 22px;
    margin-bottom: 40px;
  }

  .direction-text {
    font-weight: bold;
    color: ${(props) => (props.direction === "good" ? "#52ee7e" : "#e85968")};
  }
`;
