import React from "react";
import { ErrorMessageWrapper } from "./error.style";

function ErrorMessage() {
  return (
    <ErrorMessageWrapper>
      <div>
        <p>
          An unexpected error has occurred! The developer is looking into it.
        </p>
      </div>
    </ErrorMessageWrapper>
  );
}

export default ErrorMessage;
