import styled from "styled-components";

export const ErrorMessageWrapper = styled.div`
  color: white;
  font-size: 20px;

  @media (min-width: 425px) {
    font-size: 20px;
  }
  @media (min-width: 768px) {
    font-size: 25px;
  }
`;
