import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Icon from "react-icons-kit";
import Box from "common/components/Box";
import Button from "common/components/Button";
import Select from "common/components/Select";
import Container from "common/components/UI/Container";
import BannerWrapper, { HeaderWrapper, SearchWrapper } from "./banner.style";
import axios from "./axios";
import { search } from "react-icons-kit/feather/search";
import Results from "../Results/Results";

const BannerSection = ({ row, title, button, textArea, searchArea }) => {
  const Data = useStaticQuery(graphql`
    query {
      hostingJson {
        SECTORS {
          label
          value
        }
        SENIORITY {
          label
          value
        }
        PERFORMANCE_REVIEW {
          label
          value
        }
        OCCUPATIONS {
          label
          value
        }
      }
    }
  `);

  // Initialize vars
  var [sectorInput, setSectorInput] = useState("");
  var [seniorityInput, setSeniorityInput] = useState("");
  var [performanceReviewInput, setPerformanceReviewInput] = useState("");
  var [occupationInput, setOccupationInput] = useState("");

  // Define states
  const [showUserInput, setShowUserInput] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [layoffData, setLayoffData] = useState(null);

  /**
   * Runs each time there is an event/update in React
   */
  useEffect(() => {
    const canSubmit =
      sectorInput.value != null &&
      seniorityInput.value != null &&
      performanceReviewInput.value != null &&
      occupationInput.value != null;
    setSubmitEnabled(canSubmit);
  }, [
    sectorInput.value,
    seniorityInput.value,
    performanceReviewInput.value,
    occupationInput.value,
  ]);
  // if [], run once on load, and then not again. This array is called the DEPENDENCY ARRAY

  /**
   * Handle what happens when user populates fields and submits
   */
  function submitHandler(event) {
    setShowLoading(true);

    event.preventDefault(); // prevent browser from refreshing on submit

    async function fetchData() {
      // Set query params
      const params = {
        sector: sectorInput.value,
        performance_review: performanceReviewInput.value,
        seniority: seniorityInput.value,
        occupation: occupationInput.value,
      };
      const headers = {
        authorizationToken: "uiY7ZJtXB18z39I4oLoK",
      };
      // WAIT for the PROMISE
      await axios
        .get("layoff", {
          params: params,
          headers: headers,
        })
        .then(function (request) {
          // SUCCESS
          console.log("Success!");
          console.log(request);

          setLayoffData(request.data);
          setShowUserInput(false);

          return request;
        })
        .catch(function (error) {
          // ERROR
          // layoffData is initialized to null
          setShowUserInput(false);
          console.log("Error with AWS API Gateway: ", error.message);
        });
    }

    fetchData();
  }

  return (
    <BannerWrapper id="banner_section">
      <Container className="banner_container">
        {showUserInput && <UserInput />}
        {!showUserInput && (
          <Results
            layoffData={layoffData}
            occupationDisplay={occupationInput.label}
            performanceReviewDisplay={performanceReviewInput.label}
            seniorityDisplay={seniorityInput.label}
            sectorDisplay={sectorInput.label}
          />
        )}
      </Container>
    </BannerWrapper>
  );

  /**
   * Component for main user input
   */
  function UserInput() {
    return (
      <Box {...row}>
        <HeaderWrapper>
          <h1>How vulnerable are you to layoffs?</h1>
        </HeaderWrapper>
        <Box {...searchArea}>
          <SearchWrapper>
            <Select
              options={Data.hostingJson.SENIORITY}
              placeholder="Seniority"
              className="search_select_left"
              aria-label="select options"
              onChange={(choice) => changeSeniorityHandler(choice)}
              defaultValue={seniorityInput}
            />
            <Select
              options={Data.hostingJson.PERFORMANCE_REVIEW}
              placeholder="Annual Review"
              className="search_select"
              aria-label="select options"
              onChange={(choice) => changePerformanceReviewHandler(choice)}
              defaultValue={performanceReviewInput}
            />
            <Select
              options={Data.hostingJson.SECTORS}
              placeholder="Sector"
              className="search_select"
              aria-label="select options"
              onChange={(choice) => changeSectorHandler(choice)}
              defaultValue={sectorInput}
            />
            <Select
              options={Data.hostingJson.OCCUPATIONS}
              placeholder="Type Your Job"
              className="search_select_right"
              aria-label="select options"
              onChange={(choice) => changeOccupationHandler(choice)}
              defaultValue={occupationInput}
              labelText="Type to search from 1,000+ jobs"
              labelPosition="bottom"
            />
            <Button
              {...button}
              bg={submitEnabled ? "primary" : "gray"}
              icon={<Icon icon={search} size={24} />}
              className="domain_search_button"
              onClick={submitHandler}
              isLoading={showLoading}
              disabled={!submitEnabled}
            />
          </SearchWrapper>
        </Box>
      </Box>
    );
  }

  /**
   * Handle on change for sector dropdown
   */
  function changeSectorHandler(selectedSector) {
    setSectorInput(selectedSector);
  }

  /**
   * Handle on change for seniority dropdown
   */
  function changeSeniorityHandler(selectedSeniority) {
    setSeniorityInput(selectedSeniority);
  }

  /**
   * Handle on change for performance review dropdown
   */
  function changePerformanceReviewHandler(selectedPerformanceReview) {
    setPerformanceReviewInput(selectedPerformanceReview);
  }

  /**
   * Handle on change for occupation dropdown
   */
  function changeOccupationHandler(selectedOccupation) {
    setOccupationInput(selectedOccupation);
  }
};

BannerSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  searchArea: PropTypes.object,
  discountAmount: PropTypes.object,
  discountText: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  description: {
    fontSize: ["15px", "16px", "16px", "16px", "16px"],
    color: "#343d48cc",
    lineHeight: "1.75",
    mb: "0",
    textAlign: "center",
  },
  button: {
    title: "Search",
    type: "button",
    fontSize: "18px",
    fontWeight: "500",
    color: "#fff",
    pl: "22px",
    pr: "22px",
    iconPosition: "left",
  },
  searchArea: {
    className: "search_area",
    width: ["100%", "100%", "100%", "80%", "80%"],
    mt: ["45px", "50px", "60px", "60px", "60px"],
  },
  discountAmount: {
    fontSize: ["13px", "14px", "14px", "14px", "14px"],
    fontWeight: "600",
    color: "#eb4d4b",
    mb: 0,
    as: "span",
    mr: "0.4em",
  },
  discountText: {
    fontSize: ["13px", "14px", "14px", "14px", "14px"],
    fontWeight: "400",
    color: "#0f2137",
    mb: 0,
    as: "span",
  },
};

export default BannerSection;
