import React from "react";
import { ThemeProvider } from "styled-components";
import Sticky from "react-stickynode";
import { hostingTheme } from "common/theme/hosting";
import { GlobalStyle, ContentWrapper } from "containers/Hosting/hosting.style";
import { ResetCSS } from "common/assets/css/style";
import Navbar from "containers/Hosting/Navbar";
import FaqSection from "containers/Hosting/Faq";
import BannerSection from "containers/Hosting/Banner";
import { DrawerProvider } from "common/contexts/DrawerContext";
import { ParallaxProvider } from "react-scroll-parallax";
import { Helmet } from "react-helmet";

const Hosting = () => {
  return (
    <ThemeProvider theme={hostingTheme}>
      <ParallaxProvider>
        <Helmet>
          <title>Layoff Calculator</title>
          <meta
            name="description"
            content="Calculate your layoff probability using a data-driven approach to gather insights into your job security. Using just four questions, the Layoff Calculator calculates how likely you are to be laid off in less than a minute."
          />
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4548502153593471"
            crossorigin="anonymous"
          ></script>
        </Helmet>

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>

          <BannerSection />
          <FaqSection />
        </ContentWrapper>
      </ParallaxProvider>
    </ThemeProvider>
  );
};
export default Hosting;
